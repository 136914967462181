<template>
  <b-modal v-model="showUserInviteAddModal" centered scrollable title="Invite a User" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" style="height: 250px;">
      <b-row>
        <b-col lg="12">
          <ValidationObserver ref="UserInviteForm">
            <form @submit.prevent>
              <div class="form-group mb-0">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    v-model="vmUserEmail"
                    id="uname"
                    :placeholder="cvUserEmailLabel"/>
                  <span class="text-danger ml-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <multiselect
            v-model="vmSelectedRole"
            :tag-placeholder="ccRoleLabel"
            :placeholder="ccRoleLabel"
            :searchable="true"
            label="udr_name"
            track-by="udr_id"
            :options="getUfdRoleArray()">
          </multiselect>
        </b-col>
      </b-row>
      <b-toast
        v-model="showToast"
        name="Toast"
        :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeUserInviteAddModal()">
        Skip
      </b-button>
      <b-button size="sm" variant="primary" @click="userInviteAdd()" :disabled="submitting">
        <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
        </b-spinner>
        <span v-else>Submit</span>
      </b-button>
    </template>
  </b-modal>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { UserRole } from "../../FackApi/api/userRole"
import { User } from "../../FackApi/api/user"
import ApiResponse from "../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"

export default {
  name: "UserInviteAdd",
  props: {
    "propShowUserInviteAddModal": {
      default: false
    }
  },
  components: {
    ValidationProvider,
    Multiselect
  },
  data () {
    return {
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      cvUserEmailLabel: "Enter user email",
      ccRoleLabel: "Select a role for the user",
      showUserInviteAddModal: false,
      submitting: false,
      vmUserEmail: null,
      udfActionObj: {},
      udfActionOptions: [],
      vmSelectedRole: null,
      udfRoleListObj: [],
      selectedUdfRoles: [],
      org_type: "",
      org_id: ""
    }
  },
  mounted () {
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.submitting = false
      this.udfActionOptions = []
      this.vmUserEmail = null
      await this.setModuleData()
      await this.udfRoleList()
    },
    /**
     * setModuleData
     */
    async setModuleData () {
      if (this.$route.params.org_id) {
        this.org_type = this.$route.params.org_type
        this.org_id = this.$route.params.org_id
      }
      else if (this.$route.params.club_id) {
        this.org_type = "CLUB"
        this.org_id = this.$route.params.club_id
      }
    },
    /**
     * getUfdRoleArray
     */
    getUfdRoleArray () {
      if (this.udfRoleListObj && Object.keys(this.udfRoleListObj).length > 0) {
        return Object.keys(this.udfRoleListObj).map(key => {
          return this.udfRoleListObj[key]
        })
      }
      return []
    },
    /**
     * userInviteAdd
     */
    async userInviteAdd () {
      let valid = await this.$refs.UserInviteForm.validate()
      if (!valid) {
        return
      }

      this.submitting = true
      let moduleData = {
        user_email: this.vmUserEmail,
        org_type: this.org_type,
        org_id: this.org_id,
        role: this.vmSelectedRole ? JSON.stringify(this.vmSelectedRole) : false
      }
      try {
        let userInviteAddResp = await User.userInviteAdd(this, moduleData)
        await ApiResponse.responseMessageDisplay(this, userInviteAddResp)
        if (userInviteAddResp && !userInviteAddResp.resp_status) {
          this.submitting = false
          return false
        }
        else {
          this.$emit("emitUserInviteAdded", moduleData)
          this.submitting = false
          this.closeUserInviteAddModal()
        }
      }
      catch (err) {
        this.submitting = false
        console.error("Exception occurred at userInviteAdd() and Exception:", err.message)
      }
    },
    /**
     * closeInterestModal
     */
    closeUserInviteAddModal () {
      this.showUserInviteAddModal = false
      this.$emit("emitCloseUserInviteAddModal", true)
    },
    /**
     * udfRoleList
     */
    async udfRoleList () {
      try {
        let udfRoleListResp = await UserRole.udfRoleList(this, this.org_id)
        if (udfRoleListResp && !udfRoleListResp.resp_status) {
          return false
        }
        else {
          this.udfRoleListObj = udfRoleListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at udfRoleList() and Exception:", err.message)
      }
    }
  },
  watch: {
    async propShowUserInviteAddModal () {
      if (this.propShowUserInviteAddModal) {
        await this.loadRequiredData()
        this.showUserInviteAddModal = true
      }
    }
  }
}
</script>
