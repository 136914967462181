/*eslint-disable */
// import SocialLinkModel from '../../Model/SocialLink'
import { post } from 'jquery';
import request from '../../Utils/curl'

let SocialLinks = {
  /**
   * socialLinkList
   */
  async socialLinkList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "user_social_link_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at socialLinkList() and Exception:',err.message)
    }
  },

  /**
   * socialLinkView
   */
  async socialLinkView (context, moduleId) {
    try {
      
      let post_data = new FormData();
      post_data.append('module_id', moduleId);
      return await request.curl(context, "user_social_link_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at socialLinkView() and Exception:",err.message)
    }
    
  },

  /**
   * socialLinkAdd
   */
  async socialLinkAdd (context, socialLinkObj) {
    try{
    let post_data = new FormData();
    
    for (let key in socialLinkObj) {
      if(key === 'social_links'){
        post_data.append(key, JSON.stringify(socialLinkObj[key]))
      }
    else {
        post_data.append(key, socialLinkObj[key]);
      }

    }

    return await request.curl(context, "user_social_link_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialLinkAdd() and Exception:',err.message)
    }
  },

  /**
   * socialLinkEdit
   */
  async socialLinkEdit (context, socialLinkObj) {
    try{
    let post_data = new FormData();
    
    for (let key in socialLinkObj) {
      if(key === 'social_links'){
        post_data.append(key, JSON.stringify(socialLinkObj[key]))
      }
    else {
        post_data.append(key, socialLinkObj[key]);
      }
    }

    return await request.curl(context, "user_social_link_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialLinkEdit() and Exception:',err.message)
    }
  },

  /**
   * socialLinkDelete
   */
  async socialLinkDelete (context, moduleId) {
    try{
    let post_data = new FormData();
    
    post_data.append('module_id', moduleId);

    return await request.curl(context, "user_social_link_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialLinkDelete() and Exception:',err.message)
    }
  },

  /**
   * socialLinkProfileUpdate
   */
   async socialLinkProfileUpdate (context, socialLinkObj) {
    try{
    let post_data = new FormData();
    
    for (let key in socialLinkObj) {
      if(key === 'social_links'){
        post_data.append(key, JSON.stringify(socialLinkObj[key]))
      }
    else {
        post_data.append(key, socialLinkObj[key]);
      }
    }

    return await request.curl(context, "user_social_link_profile_update", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialLinkProfileUpdate() and Exception:',err.message)
    }
  },
}

export {
  SocialLinks
}
