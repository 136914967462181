<template>
  <b-container fluid class="p-0 pt-2 pr-3">
    <b-row>
      <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
        <!-- Backend Search -->
          <input class="form-control"
          id="search"
          v-model="whereFilter.search_param"
          type="search"
          @input="searchUserWithParams"
          :placeholder="cvSearchText"
        /><!-- Backend Search -->
      </b-col>
      <b-col sm="6" md="6" lg="4" xl="2" class="mb-2">
        <b-btn variant="primary" @click="inviteUser()">
          Invite a user
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="userListObj && userListObj.length > 0">
      <b-col lg="12">
        <b-table
          hover
          responsive
          :items="userListObj"
          :fields="columns"
          :no-border-collapse="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            {{data.index + 1}}
          </template>
          <template v-slot:cell(info)="data">
            Name: <span class="font-size-14 font-weight-bold pointer" v-if="data.item.user_name" @click="goToUserProfile(data.item)">{{data.item.user_name}}</span><br />
            Email: {{data.item.user_email}}<br />
            Role: ({{getUserRole(data.item.user_role)}})<br />
            <i class="ri-ball-pen-fill m-0 font-size-20" style="cursor: pointer;" @click="showInviteUserEdit(data.item)"></i>
          </template>
          <template v-slot:cell(role)="data">
            <span v-for="(udr_id, index) in JSON.parse(data.item.user_role_list)" :key="index">
              <b-badge
                style="cursor:pointer;"
                class="mr-2 mb-2"
                variant="primary">
                <span>{{ udr_id.user_role }}</span>
              </b-badge>
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <UserInviteAdd :propShowUserInviteAddModal="propShowUserInviteAddModal" @emitUserInviteAdded="emitUserInviteAdded" />
    <UserInviteEdit :propShowUserInviteEditModal="propShowUserInviteEditModal" :propUserInviteData="propUserInviteData" @emitUserInviteAdded="emitUserInviteAdded" />

    <b-modal v-model="showModelUserDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="udfRoleDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { User } from "../../FackApi/api/user.js"
// import ApiResponse from '../../Utils/apiResponse'
import UserInviteAdd from "./UserInviteAdd.vue"
import UserInviteEdit from "./UserInviteEdit.vue"
import UserRoles from "../../FackApi/json/UserRoles.json"

export default {
  name: "UserList",
  props: {
    propModuleName: {
      type: String,
      default: null,
      required: true
    }
  },
  components: {
    UserInviteAdd,
    UserInviteEdit
  },
  data () {
    return {
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvSearchText: "Type to Search",
      toastTitle: "User List Response",
      cvDelMessage: "Are you sure?",
      cvbtnModalCancel: "cancel",
      cvbtnModalDelOk: "Delete",
      vmRoleAddModal: false,
      vmRoleEditModal: false,
      cvRoleId: null,
      userListObj: [],
      selectedUdrRoleId: null,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Info", key: "info", class: "text-left", sortable: true },
        { label: "Role", key: "role", class: "text-left", sortable: true }
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delRoleId: null,
      showModelUserDelete: false,
      isBusy: false,
      propShowUserInviteAddModal: false,
      propShowUserInviteEditModal: false,
      propUserInviteData: {},
      whereFilter: {
        search_param: ""
      }
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * getUserRole
     * */
    getUserRole (role) {
      return UserRoles[role]
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.isBusy = true
      this.showModelUserDelete = false
      await this.userList()
      this.isBusy = false
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.userList()
    },
    /**
     * userList
     */
    async userList (loadViaLoadMore = false) {
      let moduleObjId = null

      if (this.propModuleName === "CLUB") {
        moduleObjId = this.$route.params.club_id
      }
      else {
        moduleObjId = this.$route.params.org_id
      }

      try {
        let userListResp = await User.userInviteList(this, moduleObjId, this.whereFilter)
        if (userListResp && !userListResp.resp_status) {
          return false
        }
        else {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.userListObj = [ ...userListResp.resp_data ]
          }
          else {
            this.userListObj = userListResp.resp_data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },
    /**
     * inviteUser
     */
    inviteUser () {
      this.propShowUserInviteAddModal = Math.random()
    },
    /**
     * showInviteUserEdit
     */
    showInviteUserEdit (userData) {
      this.propUserInviteData = userData
      this.propShowUserInviteEditModal = Math.random()
    },
    /**
     * selectUdrRole
     */
    selectUdrRole (udrId) {
      this.selectedUdrRoleId = udrId
    },
    /**
     * roleEdit
     */
    roleEdit (udrId) {
      this.cvRoleId = udrId
      this.vmRoleEditModal = Math.random()
    },
    /**
     * emitCloseRoleAddModal
     */
    emitCloseRoleAddModal () {
      this.vmRoleAddModal = false
    },
    /**
     * emitUserInviteAdded
     */
    emitUserInviteAdded () {
      this.loadRequiredData()
    },
    /**
     * emitUdfRoleEdit
     */
    emitUdfRoleEdit () {
      this.loadRequiredData()
      this.emitCloseRoleEditModal()
    },
    /**
     * emitCloseRoleEditModal
     */
    emitCloseRoleEditModal () {
      this.vmRoleEditModal = false
    },
    /**
     * roleDelete
     */
    async roleDelete (udrId) {
      this.showModelUserDelete = true
      this.delRoleId = udrId
    },
    /**
     * udfRoleDelete
     */
    async udfRoleDelete () {
      console.log("DELETE")
    },
    /**
     * goToUserProfile
     */
    goToUserProfile (userInfo) {
      try {
        let url = ""

        if (userInfo.user_role == "USERROLE11111") {
          url = `/user/1`
        }
        else {
          url = `/user/${userInfo.user_id}`
        }

        this.$router.push(url)
      }
      catch (err) {
        console.error("Exception occurred at goToUserProfile() and Exception:", err.message)
      }
    }
  }
}
</script>
