/*eslint-disable */
// import UserRoleModel from '../../Model/UserRole'
import request from '../../Utils/curl'

let UserRole = {
  /**
   * userRoleList
   */
  async userRoleList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "userRole_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRoleList() and Exception:',err.message)
    }
  },

  /**
   * userRoleListConsole
   */
   async userRoleListConsole (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "userRole_list_console", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRoleListConsole() and Exception:',err.message)
    }
  },
  

  /**
   * userRoleView
   */
  async userRoleView (context, userRoleId) {
    try {
      
      let post_data = new FormData();
      post_data.append('user_role_id', userRoleId);
      return await request.curl(context, "userRole_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at userRoleList() and Exception:",err.message)
    }
    
  },

  /**
   * userRoleAdd
   */
  async userRoleAdd (context, userRoleObj) {
    try{
    let post_data = new FormData();
    
    for (let key in userRoleObj) {
      post_data.append(key, userRoleObj[key]);
    }

    return await request.curl(context, "userRole_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRoleAdd() and Exception:',err.message)
    }
  },

  /**
   * userRoleEdit
   */
  async userRoleEdit (context, userRoleObj) {
    try{
    let post_data = new FormData();
    
    for (let key in userRoleObj) {
      post_data.append(key, userRoleObj[key]);
    }

    return await request.curl(context, "userRole_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRoleEdit() and Exception:',err.message)
    }
  },

  /**
   * userRoleDelete
   */
  async userRoleDelete (context, userRoleId) {
    try{
    let post_data = new FormData();
    
    post_data.append('user_role_id', userRoleId);

    return await request.curl(context, "userRole_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRoleDelete() and Exception:',err.message)
    }
  },
  /**
   * udfActionList
   */
  async udfActionList (context, moduleName) {
    try{
      let post_data = new FormData();
      post_data.append('module_name', moduleName);

      return await request.curl(context, "udfAction_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at udfActionList() and Exception:',err.message)
    }
  },
  /**
   * udfRoleAdd
   */
  async udfRoleAdd (context, udfRoleObj) {
    try{
      let post_data = new FormData();

      for (let key in udfRoleObj) {
        post_data.append(key, udfRoleObj[key]);
      }

      return await request.curl(context, "udfRole_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at udfRoleAdd() and Exception:',err.message)
    }
  },
  /**
   * udfRoleList
   */
  async udfRoleList (context, moduleObjId) {
    try{
      let post_data = new FormData();
      post_data.append('module_obj_id', moduleObjId);

      return await request.curl(context, "udfRole_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at udfRoleList() and Exception:',err.message)
    }
  },

  /**
   * udfRoleEdit
   */
  async udfRoleEdit (context, udfRoleObj) {
    try{
      let post_data = new FormData();

      for (let key in udfRoleObj) {
        post_data.append(key, udfRoleObj[key]);
      }

      return await request.curl(context, "udfRole_edit", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at udfRoleEdit() and Exception:',err.message)
    }
  },

  /**
   * udfRoleView
   */
  async udfRoleView (context, udfRoleId) {
    try {

      let post_data = new FormData();
      post_data.append('udr_id', udfRoleId);
      return await request.curl(context, "udfRole_view", post_data)
        .then(async response => {
          return response;
        });
    }
    catch (err) {
      console.error("Exception occurred at udfRoleView() and Exception:",err.message)
    }
  },
  /**
   * udfRoleDelete
   */
  async udfRoleDelete (context, udrId) {
    try{
      let post_data = new FormData();

      post_data.append('udr_id', udrId);

      return await request.curl(context, "udfRole_delete", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at udfRoleDelete() and Exception:',err.message)
    }
  },
  /**
   * udfInviteDelete
   */
  async udfInviteDelete (context, current_user_id, module_obj_id) {
    try{
      let post_data = new FormData();

      post_data.append('curr_user_id', current_user_id);
      post_data.append('module_obj_id', module_obj_id);

      return await request.curl(context, "userInvite_delete", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at udfRoleDelete() and Exception:',err.message)
    }
  },
}

export {
  UserRole
}
