<template>
  <b-container fluid class="p-0 pt-2 pr-3">
    <b-row>
      <b-col sm="6" md="6" lg="6" xl="6" class="mb-2">
        <b-form-input
          v-model="filter"
          type="search"
          :placeholder="cvSearchText">
        </b-form-input>
      </b-col>
      <b-col sm="6" md="6" lg="6" xl="6">
        <b-btn class="pull-right mr-3" variant="primary" @click="udfActionList()" v-if="userData.user_role='USERROLE11111'">Manage Actions</b-btn>
        <b-btn class="pull-right mr-3" variant="primary" @click="roleAdd()">Add Role</b-btn>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="udfRoleListObj && Object.keys(udfRoleListObj).length > 0">
      <b-col lg="12">
        <b-table
          hover
          responsive
          :items="getUfdRoleArray(udfRoleListObj)"
          :fields="columns"
          :no-border-collapse="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          :current-page="currentPage"
          :per-page="perPage"
          :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle">
              </b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            {{data.index + 1}}
          </template>
          <template v-slot:cell(actions)="data">
            <span
              v-for="(action) in data.item.actions"
              :key="action.action_id">
              <b-badge
                style="cursor:pointer;"
                class="mr-2 mb-2"
                variant="primary">
                <span>{{ action.action_name }}</span>
              </b-badge>
            </span>
          </template>
          <template v-slot:cell(action)="data">
            <b-button class="actionIcon btn mr-1 mb-1" size="sm" @click="roleEdit(data.item.udr_id)" v-if="!data.item.editable">
              <i class="font-size-20 ri-ball-pen-fill m-0"></i>
            </b-button>
            <b-button variant="actionIcon btn" @click="roleDelete(data.item.udr_id)" size="sm">
              <i class="font-size-20 ri-delete-bin-line m-0"></i>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <RoleAdd :propModuleName="propModuleName" :propShowRoleAddModal="vmRoleAddModal" @emitCloseRoleAddModal="emitCloseRoleAddModal" @emitUdfRoleAdded="emitUdfRoleAdded" />
    <RoleEdit :propModuleName="propModuleName" :propShowRoleEditModal="vmRoleEditModal" :propRoleId="cvRoleId" @emitCloseRoleEditModal="emitCloseRoleEditModal" @emitUdfRoleEdit="emitUdfRoleEdit" />

    <b-modal v-model="showModelRoleDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelRoleDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="udfRoleDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import RoleAdd from "./RoleAdd.vue"
import RoleEdit from "./RoleEdit"
import { UserRole } from "../../../FackApi/api/userRole"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "RoleList",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleName: {
      type: String,
      default: null,
      required: true
    }
  },
  components: {
    RoleAdd,
    RoleEdit
  },
  data () {
    return {
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Role List Response",
      cvDelMessage: "Are you sure?",
      cvSearchText: "Type to Search",
      cvbtnModalCancel: "cancel",
      cvbtnModalDelOk: "Delete",
      vmRoleAddModal: false,
      vmRoleEditModal: false,
      cvRoleId: null,
      udfRoleListObj: [],
      selectedUdrRoleId: null,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Role Name", key: "udr_name", class: "text-left", sortable: true },
        { label: "Allowed Actions", key: "actions", class: "text-left", sortable: true },
        { label: "Action", key: "action", class: "text-left", sortable: true }
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delRoleId: null,
      showModelRoleDelete: false,
      isBusy: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.isBusy = true
      this.showModelRoleDelete = false
      await this.udfRoleList()
      this.isBusy = false
    },
    /**
     * udfRoleList
     */
    async udfRoleList () {
      let orgId = null

      if (this.propModuleName === "CLUB") {
        orgId = this.$route.params.club_id
      }
      else {
        orgId = this.$route.params.org_id
      }

      try {
        let udfRoleListResp = await UserRole.udfRoleList(this, orgId)
        if (udfRoleListResp && !udfRoleListResp.resp_status) {
          return false
        }
        else {
          this.udfRoleListObj = udfRoleListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at udfActionList() and Exception:", err.message)
      }
    },
    /**
     * getUfdRoleArray
     */
    getUfdRoleArray () {
      if (this.udfRoleListObj && Object.keys(this.udfRoleListObj).length > 0) {
        return Object.keys(this.udfRoleListObj).map(key => {
          return this.udfRoleListObj[key]
        })
      }
      return []
    },
    /**
     * roleAdd
     */
    roleAdd () {
      this.vmRoleAddModal = Math.random()
    },
    /**
     * List & Manage Allowed Actions
     */
    udfActionList () {
      this.$router.push("/udf_action_list")
    },
    /**
     * selectUdrRole
     */
    selectUdrRole (udrId) {
      this.selectedUdrRoleId = udrId
    },
    /**
     * roleEdit
     */
    roleEdit (udrId) {
      this.cvRoleId = udrId
      this.vmRoleEditModal = Math.random()
    },
    /**
     * emitCloseRoleAddModal
     */
    emitCloseRoleAddModal () {
      this.vmRoleAddModal = false
    },
    /**
     * emitUdfRoleAdded
     */
    emitUdfRoleAdded () {
      this.loadRequiredData()
      this.emitCloseRoleAddModal()
    },
    /**
     * emitUdfRoleEdit
     */
    emitUdfRoleEdit () {
      this.loadRequiredData()
      this.emitCloseRoleEditModal()
    },
    /**
     * emitCloseRoleEditModal
     */
    emitCloseRoleEditModal () {
      this.vmRoleEditModal = false
    },
    /**
     * roleDelete
     */
    async roleDelete (udrId) {
      this.showModelRoleDelete = true
      this.delRoleId = udrId
    },
    /**
     * udfRoleDelete
     */
    async udfRoleDelete () {
      try {
        let udfRoleDeleteResp = await UserRole.udfRoleDelete(this, this.delRoleId)
        await ApiResponse.responseMessageDisplay(this, udfRoleDeleteResp)
        if (udfRoleDeleteResp && !udfRoleDeleteResp.resp_status) {
          return false
        }
        await this.loadRequiredData()
      }
      catch (err) {
        console.error("Exception occurred at udfRoleDelete() and Exception:", err.message)
      }
    }
  }
}
</script>
