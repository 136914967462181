/*eslint-disable */
// import CountryModel from '../../Model/Country'
import store from '../../store';
import request from '../../Utils/curl'

let Countries = {
  /**
   * countryList
   */
  async countryList (context, matchWithQuery= '') {
    try{
      let countryList = store.getters['Country/countryList'];
      if (countryList) {
        // cache hit!
        const response = {
          resp_status: true,
          resp_code: "OK",
          resp_data_count: countryList.length,
          resp_msg: "OK",
          resp_data: {
            count: countryList.length,
            data: countryList
          }
        }
        return response
      }
      let post_data = new FormData();

      if(matchWithQuery) {
        post_data.append('match_with_query', matchWithQuery)
      }
      
      return await request.curl(context, "country_list", post_data)
        .then(async response => {
          // save into cache
          store.commit("Country/setCountryList", response.resp_data.data)
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at countryList() and Exception:',err.message)
    }
  },

  /**
   * countryView
   */
  async countryView (context, countryId) {
    try {
      
      let post_data = new FormData();
      post_data.append('country_id', countryId);
      return await request.curl(context, "country_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at countryList() and Exception:",err.message)
    }
    
  },

  /**
   * countryAdd
   */
  async countryAdd (context, countryObj) {
    try{
    let post_data = new FormData();
    
    for (let key in countryObj) {
      post_data.append(key, countryObj[key]);
    }

    return await request.curl(context, "country_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at countryAdd() and Exception:',err.message)
    }
  },

  /**
   * countryEdit
   */
  async countryEdit (context, countryObj) {
    try{
    let post_data = new FormData();
    
    for (let key in countryObj) {
      post_data.append(key, countryObj[key]);
    }

    return await request.curl(context, "country_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at countryEdit() and Exception:',err.message)
    }
  },

  /**
   * countryDelete
   */
  async countryDelete (context, countryId) {
    try{
    let post_data = new FormData();
    
    post_data.append('country_id', countryId);

    return await request.curl(context, "country_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at countryDelete() and Exception:',err.message)
    }
  }
}

export {
  Countries
}
