<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col md="12">
        <iq-card style="padding: 0;">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="ml-2 mr-2" style="padding: 0px;">
              <CSVDownload :propModuleFile="'event_affiliate'" />
            </div>
            <div class="ml-2 mr-2" style="padding: 0px;">
              <CSVUpload :propModulePrefix="'aff'"/>
            </div>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy'}"  v-model="whereFilter.dateRange"  @update="getAffiliateDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="AffiliateObjList && AffiliateObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="AffiliateObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(user_name)="data">
                    <div @click="goToDetails('user', data.item.user_id)" style="cursor: pointer;">
                      {{data.item.user_name}}
                      <small style="color: red; display: block;">Id: {{data.item.user_id}}</small>
                    </div>
                  </template>
                  <template v-slot:cell(module_object_id)="data">
                    <div @click="goToDetails('event', data.item.module_object_id)" style="cursor: pointer;">
                      {{data.item.event_name}}
                      <small style="color: red; display: block;">Event Id: {{data.item.module_object_id}}</small>
                    </div>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Affiliates } from "../../../FackApi/api/Affiliate.js"
import CSVDownload from "../../../components/Csv/CsvDownload.vue"
import CSVUpload from "../../../components/Csv/CsvUpload.vue"

export default {
  name: "AffiliateList",
  components: {
    CSVDownload,
    CSVUpload
  },
  data () {
    return {
      cvCardTitle: "Affiliates",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Affiliate",
      cvAddModalHeader: "Add Affiliate",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Affiliate List Response",
      showModelAffiliateAdd: false,
      showModelAffiliateEdit: false,
      showModelAffiliateDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Participant", key: "user_name", class: "text-left align-text-top", sortable: true },
        { label: "Affiliate Code", key: "aff_code", class: "text-left align-text-top", sortable: true },
        { label: "Associated Module", key: "module_object_id", class: "text-left align-text-top", sortable: true }
      ],
      AffiliateObjList: null,
      AffiliateEditObj: null,
      org_id: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    await this.getDateFilter()
    this.org_id = this.$route.params.org_id
    this.affiliateList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * goToDetails
     */
    async goToDetails (page, id) {
      this.$router.push(`/${page}/${id}`)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getAffiliateDateWise () {
      this.affiliateList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * affiliateList
     */
    async affiliateList () {
      try {
        this.whereFilter = {
          ...this.whereFilter,
          org_id: this.org_id
        }
        let AffiliateListResp = await Affiliates.AffiliateList(this, this.whereFilter)
        if (AffiliateListResp.resp_status) {
          this.AffiliateObjList = AffiliateListResp.resp_data.data
          this.totalRows = AffiliateListResp.resp_data.count
        }
      }
      catch (err) {
        console.log("Exception occurred at affiliateList() and Exception:", err.message)
      }
    }
  }
}
</script>
