/*eslint-disable */
<template>
  <b-modal v-model="showRoleAddModal" centered scrollable title="Create a Role" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" style="height: 300px;">
      <b-row>
        <b-col lg="12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="vmRoleName"
              id="uname"
              :placeholder="cvRoleNameLabel"/>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="form-group">
            <b-form-group>
              <template #label>
                <b>{{ cvActionLabel }}:</b><br>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group" v-model="vmSelectedActions" name="selectedUsers" stacked>
                  <b-form-checkbox
                    v-for="action in udfActionOptions"
                    :key="action.action_id"
                    :value="action"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-4a"
                    stack>
                    <span style="cursor: pointer;">{{ action.action_name }}</span>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-toast
        v-model="showToast"
        name="Toast"
        :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeRoleAddModal()">
        Skip
      </b-button>
      <b-button size="sm" variant="primary" @click="udfRoleAdd()" :disabled="submitting">
        <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
        </b-spinner>
        <span v-else>Submit</span>
      </b-button>
    </template>
  </b-modal>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { UserRole } from "../../../FackApi/api/userRole"
import ApiResponse from "../../../Utils/apiResponse.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "RoleAdd",
  props: {
    "propShowRoleAddModal": {
      default: false
    },
    propModuleName: {
      type: String,
      default: null,
      required: true
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  data () {
    return {
      apiName: "udfRole_add",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Role",
      cvRoleNameLabel: "Enter a role name",
      cvActionLabel: "Select Actions",
      showRoleAddModal: false,
      submitting: false,
      vmRoleName: null,
      udfActionObj: {},
      udfActionOptions: [],
      vmSelectedActions: []
    }
  },
  mounted () {
  },
  methods: {
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.submitting = false
      this.udfActionOptions = []
      this.vmRoleName = null
      this.udfActionList()
    },
    /**
     * udfActionList
     */
    async udfActionList () {
      let moduleName = this.propModuleName ? this.propModuleName : "ORG"
      try {
        let udfActionListResp = await UserRole.udfActionList(this, moduleName)
        if (udfActionListResp && !udfActionListResp.resp_status) {
          return false
        }
        else {
          this.udfActionOptions = udfActionListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at udfActionList() and Exception:", err.message)
      }
    },
    /**
     * udfRoleAdd
     */
    async udfRoleAdd () {
      if (!this.vmRoleName) {
        return false
      }

      let moduleObjId = null
      if (this.propModuleName === "CLUB") {
        moduleObjId = this.$route.params.club_id
      }
      else {
        moduleObjId = this.$route.params.org_id
      }

      this.submitting = true
      let moduleData = {
        udr_name: this.vmRoleName,
        module_type: this.propModuleName,
        module_obj_id: moduleObjId,
        actions: this.vmSelectedActions && this.vmSelectedActions.length > 0 ? JSON.stringify(this.vmSelectedActions) : false
      }

      try {
        let udfRoleAddResp = await UserRole.udfRoleAdd(this, moduleData)
        await ApiResponse.responseMessageDisplay(this, udfRoleAddResp)
        if (udfRoleAddResp && !udfRoleAddResp.resp_status) {
          this.submitting = false
          return false
        }
        else {
          this.$emit("emitUdfRoleAdded", moduleData)
          this.submitting = false
          this.closeRoleAddModal()
        }
      }
      catch (err) {
        this.submitting = false
        console.error("Exception occurred at udfRoleAdd() and Exception:", err.message)
      }
    },
    /**
     * closeInterestModal
     */
    closeRoleAddModal () {
      this.showRoleAddModal = false
      this.$emit("emitCloseRoleAddModal", true)
    }
  },
  watch: {
    async propShowRoleAddModal () {
      if (this.propShowRoleAddModal) {
        this.loadRequiredData()
        this.showRoleAddModal = true
      }
    }
  }
}
</script>
