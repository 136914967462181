<template>
  <b-modal v-model="showUserInviteEditModal" centered scrollable title="Edit User Role" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" style="height: 250px;">
      <b-row>
        <b-col lg="12">
          <b-form-group>
            <template #label>
              <b>{{ ccRoleLabel }}:</b><br>
            </template>

            <template>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="selected"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-1"
                  stacked
                />
              </b-form-group>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-toast
        v-model="showToast"
        name="Toast"
        :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeUserInviteEditModal()">
        Skip
      </b-button>
      <b-button size="sm" variant="primary" @click="userInviteEdit()" :disabled="submitting">
        <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
        </b-spinner>
        <span v-else>Submit</span>
      </b-button>
    </template>
  </b-modal>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { UserRole } from "../../FackApi/api/userRole"
import { User } from "../../FackApi/api/user"
import ApiResponse from "../../Utils/apiResponse.js"

export default {
  name: "UserInviteEdit",
  props: {
    "propShowUserInviteEditModal": {
      default: false
    },
    "propUserInviteData": {
      default: {}
    }
  },
  components: {
  },
  data () {
    return {
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      cvUserEmailLabel: "Enter user email",
      ccRoleLabel: "Select a role for the user",
      showUserInviteEditModal: false,
      submitting: false,
      udfRoleListObj: [],
      org_type: "",
      org_id: "",
      mapUserId: null,
      selected: [],
      options: []
    }
  },
  mounted () {
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.submitting = false
      await this.setModuleData()
      await this.udfRoleList()
      await this.setSelectedRole()
    },
    /**
     * setSelectedRole
     */
    async setSelectedRole () {
      try {
        // Convert to Array
        if (this.propUserInviteData && this.propUserInviteData.user_role_list) {
          let userRoleList = JSON.parse(this.propUserInviteData.user_role_list)
          for (let key in userRoleList) {
            this.selected.push(userRoleList[key].user_role)
          }
        }
      }
      catch (e) {
        console.error("propUserInviteData", this.propUserInviteData, e)
      }

      this.mapUserId = this.propUserInviteData["user_id"]
    },
    /**
     * setModuleData
     */
    async setModuleData () {
      if (this.$route.params.org_id) {
        this.org_type = this.$route.params.org_type
        this.org_id = this.$route.params.org_id
      }
      else if (this.$route.params.club_id) {
        this.org_type = "CLUB"
        this.org_id = this.$route.params.club_id
      }
    },
    /**
     * userInviteEdit
     */
    async userInviteEdit () {
      this.submitting = true

      // Find the UDR Info for the Selected Roles in the UDR List
      let allocatedRoleList = []

      for (let i in this.selected) {
        for (let key in this.udfRoleListObj) {
          // console.log(this.selected[i], "ZZZZZ-222", key, this.udfRoleListObj[key].udr_name)
          if (this.udfRoleListObj[key].udr_name == this.selected[i]) {
            allocatedRoleList.push(this.udfRoleListObj[key])
          }
        }
      }

      let moduleData = {
        map_user_id: this.mapUserId,
        org_id: this.org_id,
        role: allocatedRoleList && allocatedRoleList.length > 0 ? JSON.stringify(allocatedRoleList) : false
      }

      let userInviteEditResp = null
      try {
        userInviteEditResp = await User.userInviteEdit(this, moduleData)
        if (userInviteEditResp && !userInviteEditResp.resp_status) {
          this.submitting = false
          return false
        }
        else {
          this.$emit("emitUserInviteAdded", moduleData)
          this.submitting = false
          this.closeUserInviteEditModal()
        }
      }
      catch (err) {
        this.submitting = false
        console.error("Exception occurred at userInviteEdit() and Exception:", err.message)
      }

      ApiResponse.responseMessageDisplay(this, userInviteEditResp)
    },
    /**
     * closeInterestModal
     */
    closeUserInviteEditModal () {
      this.showUserInviteEditModal = false
      this.$emit("emitCloseUserInviteEditModal", true)
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerFormData) {
          if (!this.vmCareerFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * udfRoleList
     */
    async udfRoleList () {
      try {
        let udfRoleListResp = await UserRole.udfRoleList(this, this.org_id)
        if (udfRoleListResp && !udfRoleListResp.resp_status) {
          return false
        }
        this.udfRoleListObj = udfRoleListResp.resp_data.data

        for (let key in this.udfRoleListObj) {
          delete this.udfRoleListObj[key].actions // Delete the actions key as it is of no use
          this.options.push({ text: this.udfRoleListObj[key].udr_name, value: this.udfRoleListObj[key].udr_name, udr_id: key })
        }
      }
      catch (err) {
        console.error("Exception occurred at udfRoleList() and Exception:", err.message)
      }
    }
  },
  watch: {
    async propShowUserInviteEditModal () {
      if (this.propShowUserInviteEditModal) {
        await this.loadRequiredData()
        this.showUserInviteEditModal = true
      }
    }
  }
}
</script>
